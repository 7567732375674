<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <img src="./img/yellow-bg.png" class="bg" />
    <div class="list">
      <div
        class="item"
        @click="toDetail(item, index)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="orderCode">订单号：{{ item.id }}</div>
        <div class="orderTime">下单时间：{{ item.insertTime }}</div>
        <div :class="['btn', format(item.orderStatus)]">
          {{ format2(item.orderStatus) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyOrderListUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "myFoodOrderList",
  data() {
    return {
      list: [],
      userInfo: {},
      flag: false,
      isOld: localStorage.getItem("isOld"),
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let url = getMyOrderListUrl;
      let params = {
        userId: this.userId,
      };
      this.$axios.get(`${url}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    toDetail(item, index) {
      this.$router.push({
        name: "myFoodOrderDetail",
        query: {
          id: item.id,
          isGLY: "否",
        },
      });
    },
    format(status) {
      if (status == 1) {
        return "yellow";
      } else if (status == 2) {
        return "blue";
      } else {
        return "gery";
      }
    },
    format2(status) {
      if (status == 1) {
        return "进行中";
      } else if (status == 2) {
        return "已完成";
      } else {
        return "已取消";
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  padding-bottom: 60px;
  background: #f9f9f9;
  box-sizing: border-box;
  .list {
    width: 100%;
    box-sizing: border-box;
    padding: 26px 30px 0;
    position: relative;
    .item {
      width: 100%;
      height: 230px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 22px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      box-sizing: border-box;
      padding: 38px 30px 0;
      position: relative;
      .orderCode {
        margin-bottom: 18px;
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200px;
        height: 62px;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 62px;
        text-align: center;
      }
      .yellow {
        color: #ffffff;
        background: linear-gradient(180deg, #ffde6d 0%, #ffd033 100%);
      }
      .blue {
        color: #ffffff;
        background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
      }
      .gery {
        color: rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, #ededed 0%, #d5d5d5 100%);
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
.isOld {
  .list {
    width: 100%;
    box-sizing: border-box;
    padding: 26px 30px 0;
    position: relative;
    .item {
      width: 100%;
      height: 270px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 22px;
      font-size: 46px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      box-sizing: border-box;
      padding: 38px 30px 0;
      position: relative;
      .orderCode {
        margin-bottom: 18px;
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200px;
        height: 62px;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 62px;
        text-align: center;
      }
      .yellow {
        color: #ffffff;
        background: linear-gradient(180deg, #ffde6d 0%, #ffd033 100%);
      }
      .blue {
        color: #ffffff;
        background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
      }
      .gery {
        color: rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, #ededed 0%, #d5d5d5 100%);
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
</style>
